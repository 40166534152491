import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change_password, messageClear } from '../../store/reducers/authReducer';
import toast from 'react-hot-toast';
const ChangePassword = () => {

    const dispatch = useDispatch();
    const { errorMessage, successMessage, userInfo } = useSelector(state => state.auth);
    console.log('userInfo', userInfo)
    const [state, setState] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    const inputHandle = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (state.newPassword !== state.confirmPassword) {
            toast.error("Passwords do not match.");
            return;
        }
        dispatch(change_password({ email: userInfo.email, oldPassword: state.currentPassword, newPassword: state.newPassword }));
    };

    useEffect(() => {
        if (successMessage) {
            toast.success(successMessage);
            dispatch(messageClear());
        }
        if (errorMessage) {
            toast.error(errorMessage);
            dispatch(messageClear());
        }
    }, [successMessage, errorMessage, dispatch]);

    return (
        <div className='p-4 bg-white'>
            <h2 className='text-xl text-slate-600 pb-5'>Change Password </h2>

            <form onSubmit={handleSubmit} >
                <div className='flex flex-col gap-1 mb-2'>
                    <label htmlFor="old_password">Old Password</label>
                    <input
                        onChange={inputHandle}
                        value={state.currentPassword}
                        className='outline-none px-3 py-1 border rounded-md text-slate-600'
                        type="password"
                        name="currentPassword"
                        id="currentPassword"
                        placeholder='Old Password'
                        required
                    />
                </div>

                <div className='flex flex-col gap-1 mb-2'>
                    <label htmlFor="new_password">New Password</label>
                    <input
                        onChange={inputHandle}
                        value={state.newPassword}
                        className='outline-none px-3 py-1 border rounded-md text-slate-600'
                        type="password"
                        name="newPassword"
                        id="newPassword"
                        placeholder='New Password'
                        required
                    />
                </div>

                <div className='flex flex-col gap-1 mb-2'>
                    <label htmlFor="confirm_password">Confirm Password</label>
                    <input
                        onChange={inputHandle}
                        value={state.confirmPassword}
                        className='outline-none px-3 py-1 border rounded-md text-slate-600'
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder='Confirm Password'
                        required
                    />
                </div>
                <div>
                    <button className='px-8 py-2 bg-[#059473] shadow-lg hover:shadow-green-500/30 text-white rounded-md'>Update Password </button>
                </div>


            </form>

        </div>
    );
};

export default ChangePassword;